import { Responsive, Grid, Icon, ModalContent, ModalActions } from "semantic-ui-react";
import React, { Fragment } from "react";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { PlateCategoryMetaInfo, ProductModelCategoryList, CurrentPlateDesign, VehicleType, TransactionType, Brand, PlateColor, PlateDesignModel } from "@models/ProductModels";
import { PlateDesignCard } from "@components/PlateDesignCardV2";
import theme from "@styles/theme";
import { Back } from "@components/Mobile/NavHeader/NavHeader.style";
import { BreadCrumb } from "@components/CombinationFinder/CombinationFinder.styles";
import { CheckIconColumn, ColumnGroup, CustomModal, EmptySelectionTitle, FilterDivider, FilterTitle, GroupNameColumn, HeaderDesktop, MobileHeader, ModalButton, PlateDesigListContainer, PlateStyleFilterDesktop, SelectedPlateWrapper } from "./SelectStyle.styles";
import { DesignCount, DesktopHeaderParagraph, DesktopHeaderTitle, MobileHeaderParagraph, MobileHeaderTitle, StaticDropDown } from "@components/PlateDesignsPreviewMobile/PlateDesignsPreview.styles";
import { BrandsText, CardDivider, CardDividerScondary, PlateCombination, PlateDesignInfo, PlateDesignInfoContainer, PlateDesignName, PlateDesignPricing, PlateImageContainerPreview, PlateImageContainerPreviewCard, PlateImagePreviewCard, PlateImagePreviewSelected, PlatePriceTag, PrcingFrom } from "@components/PlateDesignCardV2/PlateDesignCard.styles";
import { FontColor } from "@components/PlateDesignStyleContainerV2/MobilePlateDesignStyleContainer.styles";
import KpButton from "@elements/KpButton/KpButton";
import { buildPlateImageUrl, isDefaultCombination, isMobile } from "@utils/Helpers";
import { calculateDefaultPrice } from "@components/AddToCartPlateDesignsPreview/designPreviewHelper";
import { DefaultCardContainerPreview, SelectedIcon } from "@components/CardContainerV2/CardContainer.styles";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import { CardContainer } from "@components/CardContainerV2";

interface Props {
  apiUrl: string
    formattedCombination: string
    plateDesignList?: {
      metaInfo: PlateCategoryMetaInfo,
      products: ProductModelCategoryList
    }[],
    selectedPlateDesign?:{
      metaInfo: PlateCategoryMetaInfo
      products: ProductModelCategoryList
    }
    vehicleType: VehicleType
    title: string
    transactionType: TransactionType
    currentPlateDesign?: CurrentPlateDesign
    handleSelectCard: (data:string) => void
    isGift?: boolean,
    groupList: any
    paragraph: string
    subTitle: string,
    groupName: any,
    emptySelection: string,
    updateFilter: Function,
    filter:string
}


interface States {
  loading: boolean
  brand?: Brand
  infoBannerVisible: boolean
  loadingPrice: boolean
  plateColor: PlateColor | undefined
  frontPlate: PlateDesignModel | undefined
  backPlate: PlateDesignModel | undefined
  vehicleType: VehicleType
  categoryName: string | undefined
  price: number | undefined
  fontMap: {
      CharacterMapSmall: { [key: string]: number },
      CharacterMapLarge: { [key: string]: number }
  } | undefined,
  expandedGroup: any,
  productId: any,
  filteredPlateDesignList?: {
    metaInfo: PlateCategoryMetaInfo,
    products: ProductModelCategoryList
  }[],
  isModalOpen: boolean,
  groupName: string,
  width: number,
  selectedId: number
}

class DesktopSelectStyle extends React.Component<Props, States>{

  constructor(props: Props) {
    super(props);
    var productId = ""
    if (typeof window !== "undefined") {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        productId = urlParams.get('productid') || ""
    }

    this.state = {
        loading: true,
        infoBannerVisible: false,
        categoryName: props.selectedPlateDesign?.metaInfo.name,
        vehicleType: props.vehicleType,
        loadingPrice: !isDefaultCombination(props.formattedCombination, props.vehicleType),
        plateColor: props.currentPlateDesign?.plateColor,
        frontPlate: props.currentPlateDesign?.frontPlate,
        backPlate: props.currentPlateDesign?.backPlate,
        brand: props.currentPlateDesign?.brand,
        fontMap: undefined,
        price: calculateDefaultPrice(props.selectedPlateDesign?.metaInfo, props.transactionType),
        expandedGroup: [this.props.groupName != undefined && 999],
        productId: productId,
        isModalOpen: false,
        filteredPlateDesignList: this.props.plateDesignList,
        groupName: this.props.filter == 'All' ? "All Plates" : this.props.filter,
        width: 0,
        selectedId: 0
    }
}

componentDidMount() {
  this.handleWindowSizeChange() 
  window.addEventListener('resize', this.handleWindowSizeChange)
  this.setState({loading: false})
}

componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange)
}
handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth })
}

  goBack = () => {
    const link = this.props.transactionType.id == 1 ? "/create" : "/restyle";
    if(link){
      if(typeof window !== 'undefined'){
        window.location.href=link;
      }
    } else{
      history.back()
    }
  }

  getSelectedPlateDesignCategoryById = (id: any) => {
    let formattedCombinationNoTrailingWhitespace = this.props.formattedCombination
    if (id =='' || id == undefined) id = this.props.selectedPlateDesign?.products.categories[0].products[0].id
    var url = '/add-to-cart-desktop/?combination=' + formattedCombinationNoTrailingWhitespace + '&productid='+id+'&vt=' + this.state.vehicleType.id + "&tt=" + this.props.transactionType.id
    
    if(isMobile()){
      var url = '/add-to-cart/?combination=' + formattedCombinationNoTrailingWhitespace + '&productid='+id+'&vt=' + this.state.vehicleType.id + "&tt=" + this.props.transactionType.id
    }
    
    if (typeof window !== "undefined") {
        window.location.href = url;
    }
  }

  getArrow = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none"><path d="M6.70719 5.79402C7.09767 6.1845 7.09767 6.81863 6.70719 7.2091L1.70915 12.2071C1.31867 12.5976 0.684547 12.5976 0.294075 12.2071C-0.0963974 11.8167 -0.0963974 11.1825 0.294075 10.7921L4.58615 6.5L0.297199 2.20793C-0.0932737 1.81745 -0.0932737 1.18333 0.297199 0.792854C0.687671 0.402382 1.3218 0.402382 1.71227 0.792854L6.71032 5.7909L6.70719 5.79402Z" fill="white"/></svg>
    )
  }

  selectDesign = (data: string, id: number) => {
    this.props.handleSelectCard(data)
    this.setState({selectedId: id})
    window.scrollTo(0,0)
  }

  selectGroup = (data: string) => {
    this.props.updateFilter(data)
    if(data != "All"){
      var filteredGroupList= this.props.groupList.filter(function (item:any) {
        return item.groupName == data
      })
      
      this.setState({
        filteredPlateDesignList: filteredGroupList[0].items,
        groupName: data
      })
    } else{
      this.setState({
        filteredPlateDesignList: this.props.plateDesignList,
        groupName:'All Plates'
      })
    }   
    this.setState({isModalOpen: false})
  }

  openModal = () =>{
    this.setState({isModalOpen: true})
  }
  render() {
    const { apiUrl, formattedCombination, plateDesignList, selectedPlateDesign, vehicleType, transactionType, title, groupList, groupName, handleSelectCard  } = this.props;

    const isDefaultComb = isDefaultCombination(formattedCombination, vehicleType);
    const currentPlateDesign: CurrentPlateDesign = {
        brand: this.state.brand,
        plateColor: this.state.plateColor,
        frontPlate: this.state.frontPlate,
        backPlate: this.state.backPlate
    };

    const plateImageModel = {
        formattedCombination: formattedCombination,
        vehicleType: vehicleType,
        frontPlate: this.state.frontPlate,
        backPlate: this.state.backPlate
    }

    var bestSelling = groupList.filter((x: any) => x.groupName == 'Best Selling')
    if(bestSelling.length === 0){
        bestSelling = groupList
    }

    var filteredGroupList = groupList.filter((x: any) => x.groupName != 'Best Selling')
    var selectedGroupList = groupList.filter((x: any) => x.groupName === groupName)

    var id = this.state.productId
    var fontColors = 1
    filteredGroupList.filter((y: any) =>
        y.items.filter((z: any) =>
            z.products.categories.filter((a: any) =>
                a.products.filter((b: any) => {
                    if(b.id === parseInt(this.state.productId)){
                        id = z.products.categories[0].products[0].id
                        fontColors = z.products.categories[0].products[0].variants.length
                    } else {
                        id =''
                    }
                })
            )
        )
      )

    const imageWidth = "90%"
    const plateImageUrl = currentPlateDesign.frontPlate ? buildPlateImageUrl(apiUrl, currentPlateDesign.frontPlate, formattedCombination, 700 ) : null
    const price = selectedPlateDesign ? calculateDefaultPrice(selectedPlateDesign.metaInfo, transactionType) : 0

    return (
      <Fragment>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 10,
              bottom: 10
            },
            desktop: {
              top: 30,
              bottom: 48
            }
          }}
          backgroundColor='black'>
          {/* BreadCrumb */}
          <Responsive minWidth={theme.responsivePlateDesign.tablet.minWidth}>
            <HeaderDesktop>
                <Grid.Row mobile={16} tablet={16} computer={5} >
                <Back onClick={() => this.goBack() }>
                    <Icon name='angle left' size='large'/>
                    <span>Back</span>
                </Back>
                <BreadCrumb>
                    <a style={{color: "#FFF",fontWeight:"100"}} href={this.props.transactionType.id == 1 ? "/create/?combination="+formattedCombination + '&vt=' + vehicleType.id : "/restyle?combination="+formattedCombination + '&vt=' + vehicleType.id}>Plate Search</a>
                    {this.getArrow()}
                    <span>Select Design</span>
                    {this.getArrow()}
                    <span style={{color: "#FFF",fontWeight:"100"}}>Finalise Plate</span>
                </BreadCrumb>
                </Grid.Row>
            </HeaderDesktop>
          </Responsive>
          <Responsive maxWidth={theme.responsivePlateDesign.mobile.maxWidth}>
            <MobileHeader>
              <Grid.Row mobile={16} tablet={16} computer={5} >
                <NavHeader infoTextColor="white" link={transactionType.id == 1 ? "/create" : "/restyle"} info="Step 2/3" />
              </Grid.Row>
            </MobileHeader>
          </Responsive>
          
          {/* Header */}
          <Responsive minWidth={theme.responsivePlateDesign.desktop.minWidth}>
            <DesktopHeaderTitle>Select a Plate Design</DesktopHeaderTitle>
            <DesktopHeaderParagraph>Select one of the designs or continue to plate configuration with a Standard default design.</DesktopHeaderParagraph>
            <PlateStyleFilterDesktop>
              <button onClick={() => this.selectGroup("All")} className={this.props.filter==='All' ? 'active' : ''}>All</button>
              {this.props.groupList.map((group: any, index: any) => {
                return (<button onClick={() => this.selectGroup(group.groupName)} className={this.props.filter===group.groupName ? 'active' : ''}>{group.groupName}</button>)
              })}
            </PlateStyleFilterDesktop>
          </Responsive>
          <Responsive maxWidth={theme.responsivePlateDesign.tablet.maxWidth}>

            <MobileHeaderTitle>Select Design</MobileHeaderTitle>
            <MobileHeaderParagraph>Select one of the designs or continue to plate configuration with a Standard default design.</MobileHeaderParagraph>

            {
              selectedPlateDesign && !this.state.loading  &&
              <SelectedPlateWrapper>
                <CardContainer selected={true}>                  
                  
                    <PlateDesignInfoContainer>
                      <PlateDesignInfo>
                          <PlateDesignName fontSize='18px'>{selectedPlateDesign.metaInfo.name}</PlateDesignName>
                          <div>
                            {fontColors > 1 ?
                              <FontColor fontSize='14px'>+{fontColors || 0} font colours</FontColor> : <FontColor fontSzie='14px'>1 font colour</FontColor>
                            }
                          </div>
                          <PlateDesignPricing>
                          {selectedPlateDesign.products.brands !== undefined ? (
                              selectedPlateDesign.products.brands.length > 1 && (
                              <BrandsText>
                                  {selectedPlateDesign.products.brands.length} Designs
                              </BrandsText>
                              )
                          ) : (
                              <div style={{display:"block",height: "20px"}}>
                              </div>
                              )}
                              <PlatePriceTag color={theme.brand.primary}>
                              <PrcingFrom>{transactionType.code.charAt(0).toUpperCase() + transactionType.code.slice(1)}</PrcingFrom> ${price}
                              </PlatePriceTag>
                          </PlateDesignPricing>
                      </PlateDesignInfo>
                    </PlateDesignInfoContainer>
                    <PlateImageContainerPreviewCard>
                      <PlateImagePreviewSelected
                          src={plateImageUrl}
                          width={imageWidth}
                          alt={`${formattedCombination} plate image`}
                      />
                    </PlateImageContainerPreviewCard>
                  
                </CardContainer>

                  <div id='select-style-continue' style={{marginTop:"20px", marginBottom:"20px", width:"100%"}}>
                      <KpButton fullWidth="mobile" id='select-style-continue' color={theme.brand.colors.blue} buttonType='primary' onClick={() => this.getSelectedPlateDesignCategoryById(this.state.selectedId)}>
                              CONTINUE
                      </KpButton>
                  </div>
              </SelectedPlateWrapper>
            }

            <PlateStyleFilterDesktop>
              <FilterTitle>Plate Designs</FilterTitle>
              <StaticDropDown onClick={() =>this.openModal()}>
                {this.state.groupName}
                <Icon name={'angle down'} size='large' />
              </StaticDropDown>
            </PlateStyleFilterDesktop>
            <CustomModal basic open={this.state.isModalOpen} onClose={() => {this.setState({isModalOpen: false})}} onOpen={() => {this.setState({isModalOpen: true})}} closeIcon centered>
              <ModalContent><p></p></ModalContent>
              <ModalActions>
              <FilterDivider />       
                <Grid>           
                
                {this.props.filter==='All' && 
                  <CheckIconColumn>
                    <Icon name="check"></Icon>
                  </CheckIconColumn>
                  }
                  <GroupNameColumn width={14} onClick={() => this.selectGroup("All")} >                    
                    <ModalButton className={this.props.filter==='All' ? 'active' : ''}>All Plates</ModalButton>
                    <DesignCount>17 Designs</DesignCount>
                  </GroupNameColumn>
                </Grid>
                
                {this.props.groupList.map((group: any, index: any) => {
                  return (<>
                    <FilterDivider />
                    <Grid>                      
                      {this.props.filter===group.groupName && 
                      <CheckIconColumn>
                        <Icon name="check"></Icon>
                      </CheckIconColumn>}
                      <GroupNameColumn width={14} onClick={() => this.selectGroup(group.groupName)} >
                        <ModalButton className={this.props.filter===group.groupName ? 'active' : ''}>{group.groupName}</ModalButton>
                        <DesignCount>{group.designCounts} Designs</DesignCount>
                      </GroupNameColumn>
                    </Grid></>)
                })}
              </ModalActions>
            </CustomModal>
          </Responsive>
          
          {/* Cards */}
          {!this.state.loading && <PlateDesigListContainer>            

            <Responsive minWidth={theme.responsivePlateDesign.desktop.minWidth} as={Grid} columns={2}>
              <Grid.Column  width={this.state.width >= 1200 ? 12 :8}>
              {this.props.filter === 'All' &&<ResponsiveGrid>
                  {
                    this.state.filteredPlateDesignList!.map((list, key) => {
                      const selected = (selectedPlateDesign && list.metaInfo.name ===  selectedPlateDesign.metaInfo.name) ? true : false;
                      return (
                        <Grid.Column key={key}>
                          <PlateDesignCard apiUrl={apiUrl}
                            cardKey={key}
                            vehicleType={vehicleType}
                            metaInfo={list.metaInfo}
                            transactionType={this.props.transactionType}
                            formattedCombination={formattedCombination}
                            productCategoryList={list.products}
                            handleSelectCard={this.selectDesign}
                            selected={selected} />
                        </Grid.Column>
                      )
                    })
                  }
                </ResponsiveGrid>}
                {this.props.filter != 'All' &&<ResponsiveGrid>
                  {
                    this.props.groupList.filter((x: any) => x.groupName === this.props.filter)[0].items.map((list : any, key: any) => {
                      const selected = (selectedPlateDesign && list.metaInfo.name === selectedPlateDesign.metaInfo.name) ? true : false;
                      return (
                        <Grid.Column key={key}>
                          <PlateDesignCard apiUrl={apiUrl}
                            cardKey={key}
                            vehicleType={vehicleType}
                            metaInfo={list.metaInfo}
                            transactionType={this.props.transactionType}
                            formattedCombination={formattedCombination}
                            productCategoryList={list.products}
                            handleSelectCard={this.selectDesign}
                            selected={selected} />
                        </Grid.Column>
                      )
                    })
                  }
                </ResponsiveGrid>}
              </Grid.Column>
              <Grid.Column key="1222"  width={this.state.width >= 1200 ? 2 : 8}>
                <DefaultCardContainerPreview floatRight={this.state.width >= 1200 ? false : true}>
                  <PlateImageContainerPreview>
                    Your Plate
                  </PlateImageContainerPreview>
                  <CardDivider />
                  {selectedPlateDesign ? <>
                    <PlateDesignInfoContainer>
                      <PlateDesignInfo>
                        <PlateDesignName>{selectedPlateDesign ? selectedPlateDesign.metaInfo.name : ''}</PlateDesignName>
                        <PlateDesignPricing>
                          <PrcingFrom>From&nbsp;</PrcingFrom>
                          <PlatePriceTag color={theme.brand.primary}>
                            ${price}
                          </PlatePriceTag>
                        </PlateDesignPricing>
                      </PlateDesignInfo>
                      {plateImageUrl != undefined && 
                      <PlateImagePreviewCard
                                    src={plateImageUrl}
                                    width={imageWidth}
                                    alt={`${formattedCombination} plate image`}
                                />}
                      {plateImageUrl === undefined && 
                      <React.Fragment>
                      <CardDividerScondary/>
                        <PlateCombination>{formattedCombination}</PlateCombination>
                      <CardDividerScondary/>
                      </React.Fragment>
                      }
                    </PlateDesignInfoContainer>
                    <div id='select-style-continue' style={{marginTop:"20px", width:"100%"}}>
                      <KpButton id='select-style-continue' color={theme.brand.colors.blue} buttonType='primary' onClick={() => this.getSelectedPlateDesignCategoryById(this.state.selectedId)}>
                              CONTINUE
                      </KpButton>
                    </div>
                  </> :
                  <>
                  <EmptySelectionTitle>{this.props.emptySelection}</EmptySelectionTitle>
                  <CardDividerScondary/>
                        <PlateCombination>{formattedCombination}</PlateCombination>
                      <CardDividerScondary/>
                  </>
                  }
                </DefaultCardContainerPreview>
              </Grid.Column>
            </Responsive>

            <Responsive maxWidth={theme.responsivePlateDesign.tablet.maxWidth} as={Grid} columns={1}>
              <ColumnGroup  width={16}>
              {this.props.filter === 'All' &&<ResponsiveGrid>
                  {
                    this.state.filteredPlateDesignList!.map((list, key) => {
                      const selected = (selectedPlateDesign && list.metaInfo.name ===  selectedPlateDesign.metaInfo.name) ? true : false;
                      return (
                        <Grid.Column key={key}>
                          <PlateDesignCard apiUrl={apiUrl}
                            cardKey={key}
                            vehicleType={vehicleType}
                            metaInfo={list.metaInfo}
                            transactionType={this.props.transactionType}
                            formattedCombination={formattedCombination}
                            productCategoryList={list.products}
                            handleSelectCard={this.selectDesign}
                            selected={selected} />
                        </Grid.Column>
                      )
                    })
                  }
                </ResponsiveGrid>}
                {this.props.filter != 'All' &&<ResponsiveGrid>
                  {
                    this.props.groupList.filter((x: any) => x.groupName === this.props.filter)[0].items.map((list : any, key: any) => {
                      const selected = (selectedPlateDesign && list.metaInfo.name === selectedPlateDesign.metaInfo.name) ? true : false;
                      return (
                        <Grid.Column key={key}>
                          <PlateDesignCard apiUrl={apiUrl}
                            cardKey={key}
                            vehicleType={vehicleType}
                            metaInfo={list.metaInfo}
                            transactionType={this.props.transactionType}
                            formattedCombination={formattedCombination}
                            productCategoryList={list.products}
                            handleSelectCard={this.selectDesign}
                            selected={selected} />
                        </Grid.Column>
                      )
                    })
                  }
                </ResponsiveGrid>}
              </ColumnGroup>
            </Responsive>

          </PlateDesigListContainer>}
        </GenericBannerContainer>
      </Fragment>
    );
  }
}

const ResponsiveGrid = ({ children }: any) => {
 
  return (
    <Fragment>
      <Responsive maxWidth={389} as={Grid} columns={1}>
        {children}
      </Responsive>
      <Responsive minWidth={390} maxWidth={767} as={Grid} columns={2}>
        {children}
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023} as={Grid} columns={3}>
        {children}
      </Responsive> 
      <Responsive minWidth={1024} maxWidth={1199} as={Grid} columns={2}>
        {children}
      </Responsive>
      <Responsive minWidth={1200} maxWidth={1439} as={Grid} columns={3}>
        {children}
      </Responsive>
      <Responsive minWidth={1440} as={Grid} columns={4}>
        {children}
      </Responsive>
    </Fragment>
  )
}
export default DesktopSelectStyle;